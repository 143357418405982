import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const {currentUser} = useContext(AuthContext);
  let params = window.location.search.slice(1);
  const pathname = window.location.pathname;

  let path;
  if(pathname) {
    path = pathname.substr(1);
  }

  params = params && params !== '' ? path ? `&${params}` : `?${params}`: '';
  path = path ? `?path=${path}` : '';

  return (
    <Route
      {...rest}
      render={routeProps =>
        !!currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={`/signup${path}${params}`} />
        )
      }
    />
  );
};


export default PrivateRoute