import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";
import 'firebase/storage';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

const perf = firebase.performance();
const db = firebase.firestore();
const analytics = firebase.analytics();
const functions = firebase.functions();

// firebase.functions().useFunctionsEmulator("http://localhost:5001")

/*
if (window.location.hostname === "localhost") {
  console.log("Setting Localhost for Firebase");
  db.settings({
    host: "localhost:8080",
    ssl: false
  });
}
*/

export default firebase;
export { db, analytics, perf, functions };
