import firebase, { db } from "./firebase";
import publicIp from "public-ip";
import iplocation from "iplocation";

let ipAddress;
let location = {};
const getIP4 = async () => {
  try {
    ipAddress = await publicIp.v4();
    location = await iplocation(ipAddress);
  } catch (err) {
    console.error("Error getting IP: " + err);
  }
};
getIP4();

const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const processUser = async (data) => {
  const { displayName, picture } = data;
  const currentUser = firebase.auth().currentUser;
  const uid = currentUser.uid;

  const usersRef = db.collection("users").doc(uid);
  await usersRef
    .get()
    .then((docSnapshot) => {
      const updateUser = {
        created: firebase.firestore.Timestamp.fromDate(new Date()),
        email: currentUser.email,
        uid,
        location,
      };

      if(displayName) {
        updateUser.displayName = displayName;
      }
      if(picture) {
        updateUser.picture = picture;
      }

      usersRef.set(updateUser, { merge: true }).catch(console.error);
    })
    .catch(console.error);
};

const processSocialAuth = async (
  authResult,
  redirectUrl,
  location,
  history
) => {
  let data = {};

  if (authResult.additionalUserInfo.providerId === "google.com") {
    console.log("Google Login");
    const displayName = authResult.additionalUserInfo.profile.given_name;
    const picture = authResult.additionalUserInfo.profile.picture;

    data = {
      displayName,
      picture,
    };
  }

  await processUser(data);

  const query = new URLSearchParams(location.search);
  const path = query.get("path");
  const url = path ? path : "";
  query.delete("path"); // remove path
  const qs = query.toString();

  const moveLoc = `/${url}?${qs}`;

  // add to be sure user is first created
  await timeout(1000);

  move(history, moveLoc);
};

const move = (history, redirectUrl) => {
  console.log("moving: " + redirectUrl);
  history.push(redirectUrl);
};

export { processUser, processSocialAuth, move };
