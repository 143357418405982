import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './assets/css/index.css';
import "./assets/css/App.css";
import * as serviceWorker from './serviceWorker';

import "@fortawesome/fontawesome-free/css/all.min.css";

import './assets/font/scss/fontawesome.scss';
import './assets/font/scss/light.scss';
// import './assets/font/scss/regular.scss';
import './assets/font/scss/solid.scss';
// import './assets/font/scss/duotone.scss';
import './assets/font/scss/brands.scss';

import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

// import 'mdbreact/dist/mdbreact';

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
