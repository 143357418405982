import React, { useState } from "react";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBCard } from "mdbreact";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebaseui from "firebaseui";
import PulseLoader from "react-spinners/PulseLoader";

import firebase from "../lib/firebase";
import { processSocialAuth, move } from "../lib/auth.js";

import logo from "../assets/images/hoppad-logo.png";

const SUPPORT_EMAIL = "contact@hoppad.com";

let dirty = false;

const SignUp = ({ history }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const fbAuth = firebase.auth();

  /* if already logged in move */
  if (!dirty && fbAuth.currentUser) {
    dirty = true;
    console.log("moving signup");
    const params = window.location.search.slice(1);
    move(history, `/${params ? `?${params}` : ""}`);
  } else {
    dirty = true;
  }

  // Configure FirebaseUI.
  const uiConfig = {
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        setLoading(true);
        processSocialAuth(authResult, redirectUrl, location, history);

        return false;
      },
    },
  };

  window.scroll(0, 0);

  const toggleRegister = () => setIsRegister(!isRegister);

  return (
    <>
      <br />
      <br />
      <MDBContainer>
        <MDBRow>
          <MDBCol md="6" className="ml-auto mr-auto">
            <MDBCard>
              <div className="header pt-3">
                <MDBRow className="d-flex justify-content-start">
                  <h3 className="white-text mt-3 mb-4 pb-1 mx-5">
                    <img
                      src={logo}
                      alt="seersite logo"
                      className="logo-login"
                    />
                  </h3>
                </MDBRow>
              </div>
              <br />
              <div className="signup-msg">
                <center>
                  <h4>
                    {isRegister
                      ? "Create a New Parent Account"
                      : "Access Your Parent Account"}
                  </h4>
                </center>
              </div>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
              {loading && (
                <center>
                  <PulseLoader
                    sizeUnit={"px"}
                    size={24}
                    color={"blue"}
                    loading={loading}
                  />
                  <br />
                </center>
              )}
              <br />
              <span className="font-small grey-text text-center margin-terms signup-pp">
                <span onClick={() => toggleRegister()}>
                  <span className="color-mdb-lighten-1 link">
                    {isRegister ? "Sign In to Parent Account" : "Create a New Parent Account"}
                  </span>
                </span>
              </span>
              <span className="font-small grey-text text-center margin-terms signup-pp">
                <span
                  onClick={() =>
                    window.alert(
                      'To recover your password, click "Sign in with Email", enter your email address, and click "Trouble signing in?".'
                    )
                  }
                >
                  <span className="color-mdb-lighten-1 link">
                    Forgot Password
                  </span>
                </span>
              </span>
              <span className="font-small grey-text text-center margin-terms signup-pp">
                Please see our{" "}
                <a
                  href="https://hoppad.com/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="color-mdb-lighten-1">Terms</span>
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://www.hoppad.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="color-mdb-lighten-1">Privacy Policy</span>
                </a>
                <br/>
                YouTube's{" "}
                <a
                  href="https://www.youtube.com/t/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="color-mdb-lighten-1">Terms of Service</span>
                </a>
                &nbsp;and&nbsp;Google's{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="color-mdb-lighten-1">Privacy Policy</span>
                </a>
              </span><br/>
              <p className="font-small grey-text d-flex justify-content-center">
                Need help?{" "}
                <a
                  href={`mailto:${SUPPORT_EMAIL}?subject=Help%20on%20HopPad`}
                  className="dark-grey-text font-weight-bold ml-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="color-mdb-lighten-1">Email us</span>.
                </a>
                .
              </p>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default withRouter(SignUp);
