import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import SignUp from "./pages/SignUp";

import { AuthProvider } from "./components/Auth";
import PrivateRoute from "./components/PrivateRoute";

const Home = lazy(() => import("./pages/Home"));
const Blocked = lazy(() => import("./pages/Blocked"));
const Error404 = lazy(() => import("./pages/Error404"));

class Routes extends Component {
  render() {
    return (
      <AuthProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <Suspense fallback={<div></div>}>
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute path="/blocked" component={Blocked} />
              <Route path="/signup" component={SignUp} />
              <Route component={Error404} />
            </Switch>
          </Suspense>
        </Router>
      </AuthProvider>
    );
  }
}

export default Routes;
